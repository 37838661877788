import React from "react";
import IntroCard1  from "./intro_card_cv";
import NavBar from "./navbar";

function Home(){
    return <div>
    {/* <NavBar/> */}
    <IntroCard1/>
    </div>
}


export default Home;